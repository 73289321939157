export {createNewsSeriesRankingBlock};

function createNewsSeriesRankingBlock(data, options, viewer) {
  //共通レイアウト　連載ランキング
  if (data.ranking.length > 0) {
    data.ranking.forEach($row => {
        const $li = document.createElement('li');
        $li.classList.add('swiper-slide',);
        {
          const $article = document.createElement('article');
          $article.classList.add('series-article');
          {
            const $a = document.createElement('a');
            $a.classList.add('gtm-click');
            $a.id = 'clickID-newsseries_popular_ranking_' + $row.id;
            $a.href = '/smilenews/newsseries/detail/' + $row.id;
            {
              const $div = document.createElement('div');
              $div.classList.add('is-article-thumb');
              {
                const $figure = document.createElement('figure');
                $figure.classList.add('is-article-img-trim');
                {
                  const $img = document.createElement('img');
                  $img.classList.add('is-article-img', 'is-w100');
                  $img.src = $row.images.square.small;
                  $img.alt = $row.title;
                  $img.loading = 'lazy';
                  $figure.appendChild($img);
                }
                $div.appendChild($figure);
              }
              $a.appendChild($div);

              const $p = document.createElement('p');
              $p.classList.add('fw-bold', 'line-clamp', 'is-1');
              $p.textContent = $row.title;
              $a.appendChild($p);

              const $p2 = document.createElement('p');
              $p2.classList.add('line-clamp', 'is-1', 'fz-sm', 'c-gray');
              $p2.textContent = $row.author.name;
              $a.appendChild($p2);
            }
            $article.appendChild($a);
          }
          $li.appendChild($article);
        }
        viewer[0].appendChild($li);
      });

    return true;
  }
}

