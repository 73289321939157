import {formatDate} from '../formatDate';

export {createKeywordsNewsSeriesKeywordsBlock};

function createKeywordsNewsSeriesKeywordsBlock(data, options) {

  //ニュース連載キーワード
  if (data.keywords.length > 0) {
    const $section = document.createElement('section');
    {
      data.keywords.forEach($row => {
        const $a = document.createElement('a');
        $a.classList.add('link', 'is-icon');
        $a.href='/smilenews/newsseries/keyword/' + $row.id;
        {
          const $span = document.createElement('span');
          $span.classList.add('babysymbol', 'babysymbol-tag', 'mr5', 'c-pink');
          $a.appendChild($span);
        }
        {
          const $text = document.createElement('span');
          $text.textContent = $row.name;
          $a.appendChild($text);
        }
        $section.appendChild($a);
      });
    }
    return $section;
  }
}

