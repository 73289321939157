
export {createTopicArticleBlock};

function createTopicArticleBlock(data, options) {
  const $body = document.createElement('div');
  {
    if (data.articles.length > 0) {
      const $div = document.createElement('div');
      $div.classList.add('side-search-article', 'gutter-around');
      {
        const $h3 = document.createElement('h3');
        $h3.classList.add('title', 'is-border');
        let $viewer = '産後ケア施設トピックス';
        if (options.viewer === 'hospital') {
          $viewer = '産婦人科トピックス';
        }
        $h3.textContent = $viewer;
        $div.appendChild($h3);

        data.articles.forEach($row => {
          const $article = document.createElement('div');
          $article.classList.add('article-list', 'mb10');
          {
            const $a = document.createElement('a');
            $a.classList.add('row','is-article');
            $a.href = '/' + options.viewer +'/article/' + $row.category_english_name + '/' + $row.article_id;
            {
              const $div3 = document.createElement('div');
              $div3.classList.add('col-xs-4', 'is-article-thumb');
              {
                const $div4 = document.createElement('div');
                $div4.classList.add('is-article-img-trim');
                {
                  const $img = document.createElement('img');
                  $img.classList.add('is-article-img', 'is-w100');
                  $img.src = $row.thumb_image.replace('/upload/hospital_article_thumb/', '/upload/hospital_article_thumbL/');
                  $img.alt = $row.title;
                  $img.loading = 'lazy';
                  $div4.appendChild($img);
                }
                $div3.appendChild($div4);
              }
              $a.appendChild($div3);

              const $div5 = document.createElement('div');
              $div5.classList.add('col-xs-8', 'is-article-info');
              {
                const $div6 = document.createElement('div');
                $div6.classList.add('is-article-title', 'line-clamp', 'is-2');
                $div6.textContent = $row.title;
                $div5.appendChild($div6);

                const $span = document.createElement('span');
                $span.classList.add('label-round', 'is-palegreen', 'is-small');
                $span.textContent = $row.category_name;
                $div5.appendChild($span);
              }
              $a.appendChild($div5);
            }
            $article.appendChild($a);
          }
          $div.appendChild($article);
        })

        const $more = document.createElement('a');
        $more.classList.add('button', 'is-fullsize');
        $more.href = '/' + options.viewer + '/article';
        $more.textContent = 'もっと見る';
        {
          const $span3 = document.createElement('span');
          $span3.classList.add('babysymbol', 'babysymbol-arrowright', 'fz-i', 'ml10');
          $more.appendChild($span3);
        }
        $div.appendChild($more);
      }
      $body.appendChild($div);
    }
  }
  return $body;
}
