
export {createKeywordBlock};

function createKeywordBlock(data, options) {
  //注目キーワード
  if (data.items.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('mb10');
    {
      const $keywords = data.items;
      if ($keywords.length > 0) {
        for (let i = 0; i < 8; i++) {
          const $link = document.createElement('a');
          $link.classList.add('link', 'is-box', 'is-blue');
          $link.href = $keywords[i].url;
          $link.textContent = $keywords[i].label;
          $div.appendChild($link);
        }
      }
    }
    return $div;
  }
}

