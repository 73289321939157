export {createModalNewsArticlesBlock};

function createModalNewsArticlesBlock(data, options) {
  const $div = document.createElement('div');
  if (data.news.length > 0) {
    for(let $i = 0; $i < data.news.length; $i++) {
      const $div1 = document.createElement('div');
      $div1.classList.add('news-article');
      {
        const $a = document.createElement('a');
        $a.classList.add('row', 'gtm-click');
        $a.id = 'clickID-news_recommend_modal_click_' + data.news[$i].id;
        $a.href = '/smilenews/detail/' + data.news[$i].id;
        {
          const $div2 = document.createElement('div');
          $div2.classList.add('col-xs-4', 'col-sm-3','is-article-thumb');
          {
            const $figure = document.createElement('figure');
            $figure.classList.add('is-article-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img', 'is-w100');
              $img.src = data.news[$i].thumb_image;
              $img.alt = data.news[$i].title;
              $img.loading = 'lazy';
              $figure.appendChild($img);
            }
            $div2.appendChild($figure);
          }
          $a.appendChild($div2);

          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-8', 'col-sm-9');
          {
            const $p = document.createElement('p');
            $p.classList.add('title', 'is-4');
            $p.textContent = data.news[$i].title;
            $div3.appendChild($p);
          }
          $a.appendChild($div3);
        }
        $div1.appendChild($a);
      }
      $div.appendChild($div1);
    }
  }
  return $div;
}

