import createAPI from './APIRequest';
import {addBallotPercent} from "./newsBallot";

export {clipAnswer};

const API = createAPI();
function _clipAnswer(el) {
  el.addEventListener('click', (event) => {
    API.send(
      el.dataset.voteUrl,
      el.dataset.voteMethod,
      el.dataset.voteAuth,
      el.dataset.voteBody,
      () => {},
      (response) => {
        API.send(
          el.dataset.voteUrl + '?question_id=' + el.dataset.voteQuestionId + '&access_token=' + el.dataset.voteAccessToken,
          'GET',
          el.dataset.voteAuth,
          null,
          function() {},
          function (response) {
            const $ballot_card = el.closest('.ballot-card');

            $ballot_card.classList.remove('is-before');
            $ballot_card.classList.add('is-after');
            let after = $ballot_card.querySelectorAll('.is-before');
            for(const x of after) x.classList.replace("is-before", "is-after");

            const title = $ballot_card.querySelector('.ballot-tag');
            title.textContent = '回答済';
            $ballot_card.querySelector('.ballot-card-detail').remove();
            $ballot_card.appendChild(createVotesGraphBlock(response.data));
            addBallotPercent();
          }
        );
      },
      (status, response) => {
      }
    );
  });
}

async function clipAnswer(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipAnswer(el));
  }
}

function createVotesGraphBlock(data) {
  if (data.questions.length > 0) {
    const $graph_detail = document.createElement('div');
    $graph_detail.classList.add('ballot-card-detail');
    data.questions.forEach($row => {
      {
        const $p = document.createElement('p');
        $p.classList.add('ballot-notes');
        $p.textContent = 'あなたの回答：' + $row.my_vote_answer;
        $graph_detail.appendChild($p);

        $row.answers.forEach($each_answer => {
          const $graph = document.createElement('div');
          $graph.classList.add('ballot-graph');
          {
            const $ans_span = document.createElement('span');
            $ans_span.classList.add('ballot-graph-text');
            $ans_span.textContent = $each_answer.answer;
            $graph.appendChild($ans_span);

            const $percent_span = document.createElement('span');
            $percent_span.classList.add('ballot-graph-number');
            $percent_span.setAttribute('data-percentage', $each_answer.vote_percent + '%');
            $graph.appendChild($percent_span);
          }
          $graph_detail.appendChild($graph);
        });
        const $notes = document.createElement('p');
        $notes.classList.add('ballot-notes','is-right');
        $notes.textContent = $row.total_vote_count + '票・' + $row.close_date + 'まで';
        $graph_detail.appendChild($notes);
      }
    });
    return $graph_detail;
  }
}
