import {formatDate} from '../formatDate';

export {createIndexNewsTopBlock};

function createIndexNewsTopBlock(data, options) {
  //ニューストップ　トップ記事
  if (data.news.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('news-article-1clm');
    {
      for(const $row of data.news){
        const $article = document.createElement('article');
        $article.classList.add('news-article');
        {
          const $a = document.createElement('a');
          $a.classList.add('row', 'middle-xs');
          $a.href = '/smilenews/detail/' + $row.id;
          {
            const $div2 = document.createElement('div');
            $div2.classList.add('col-xs-12', 'col-sm-6', 'is-article-thumb');
            {
              const $figure = document.createElement('figure');
              $figure.classList.add('is-article-img-trim');
              {
                const $img = document.createElement('img');
                $img.classList.add('is-article-img', 'is-w100');
                $img.src = $row.thumb_image;
                $img.alt = $row.title;
                $img.loading = 'lazy';
                $figure.appendChild($img);

                const $span = document.createElement('span');
                $span.classList.add('label-news', 'u-bg_category-' + $row.category_english_name);
                $span.textContent = $row.category_name;
                $figure.appendChild($span);
              }
              $div2.appendChild($figure);
            }
            $a.appendChild($div2);

            const $div3 = document.createElement('div');
            $div3.classList.add('col-xs-12', 'col-sm-6');
            {
              const $div4 = document.createElement('div');
              $div4.classList.add('title', 'is-2');
              $div4.textContent = $row.title;
              $div3.appendChild($div4);

              const $div5 = document.createElement('div');
              $div5.classList.add('row', 'between-xs', 'fz-sm', 'news-article-info');
              {

                const $div6 = document.createElement('div');
                $div6.classList.add('col-xs-9');
                {
                  //open_date
                  const $time = document.createElement('time');

                  $time.dateTime = $row.open_date;
                  $time.textContent = formatDate(new Date($row.open_date));
                  $div6.appendChild($time);

                  //is_new
                  const $limit = Date.parse($row.open_date);
                  const $current = new Date();
                  $current.setHours($current.getHours() - 24);

                  if ($limit >= $current.getTime()) {
                    const $span2 = document.createElement('span');
                    $span2.classList.add('label-round', 'is-new', 'is-pink', 'ml5');
                    $span2.textContent = 'NEW';
                    $div6.appendChild($span2);
                  }
                }
                $div5.appendChild($div6);

                //comments_count
                const $comments_count = $row.comments_count;
                if ($comments_count > 0) {
                  const $div7 = document.createElement('div');
                  $div7.classList.add('col-xs', 'end-xs');
                  {
                    const $span3 = document.createElement('span');
                    if ($comments_count > 10) {
                      $span3.classList.add('c-news-talk-red');
                    } else {
                      $span3.classList.add('c-news-talk-blue');
                    }
                    {
                      const $i = document.createElement('i');
                      $i.classList.add('babysymbol', 'babysymbol-talk', 'mr5');
                      $span3.appendChild($i);

                      const $span3_text = document.createElement('span');
                      $span3_text.textContent = $comments_count;
                      $span3.appendChild($span3_text);
                    }
                    $div7.appendChild($span3);
                  }
                  $div5.appendChild($div7);
                }
              }
              $div3.appendChild($div5);
            }
            $a.appendChild($div3);
          }
          $article.appendChild($a);
        }
        $div.appendChild($article);
        break;
      };
    }
    return $div;
  }
}

