
export {createQandaBlock};

function createQandaBlock(data, options) {
  //医師・専門家が回答Q&A
  if (data.articles.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('mb10');
    {
      data.articles.forEach($row => {
        const $qblock = document.createElement('div');
        $qblock.classList.add('button-flex', 'fw-bold');
        {
          const $qsign = document.createElement('span');
          $qsign.classList.add('babysymbol','babysymbol-question','fz-i','mr10','c-green');
          $qblock.appendChild($qsign);

          const $qlink = document.createElement('a');
          let link = $row.uri;
          const haedTags = ['prepregnancy/','pregnancy/','birth/','baby/','infertility/'];
          haedTags.forEach($row => {
            if((link.indexOf($row) !== -1))
            {
              link = link.replace($row, '');
            }
          })
          $qlink.href = link;
          $qlink.textContent = $row.title;
          $qblock.appendChild($qlink);
        }
        $div.appendChild($qblock);
      });
    }
    return $div;
  }
}

