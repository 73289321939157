
export {createExperienceBlock};

function createExperienceBlock(data, options) {
  //みんなの体験談
  if ((data.articles.length > 0)) {
    const $div = document.createElement('div');
    {
      data.articles.forEach($row => {
        const $a = document.createElement('a');
        $a.classList.add('link', 'is-arrow');
        $a.href = $row.uri;
        {
          const $span = document.createElement('span');
          $span.classList.add('babysymbol', 'babysymbol-arrowright', 'c-pink', 'mr5');
          $a.appendChild($span);
        }
        {
          const $text = document.createElement('span');
          $text.textContent = $row.title;
          $a.appendChild($text);
        }
        $div.appendChild($a);
      })
    }
    return $div;
  }
}

