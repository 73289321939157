import {formatDate} from '../formatDate';
import {htmlEscape} from './createNewsRankingBlock';

export {createNewsSeriesThemeRankingBlock};

function createNewsSeriesThemeRankingBlock(data, options) {

  const $div = document.createElement('div');
  if (options.element === 'indexNewsSeriesThemeRanking') {
    $div.classList.add('news-article-list', 'is-rank');
  }else {
    $div.classList.add('news-article-list');
  }
  {
    if (data.length > 0) {
      data.forEach($row => {
        const $article = document.createElement('article');
        $article.classList.add('news-article');
        {
          const $a = document.createElement('a');
          $a.classList.add('row', 'middle-xs');
          $a.href = '/smilenews/newsseries/detail/' + $row.id;
          {
            const $div3 = document.createElement('div');
            $div3.classList.add('col-xs-4', 'col-sm-3', 'is-article-thumb');
            {
              const $figure = document.createElement('figure');
              $figure.classList.add('is-article-img-trim');
              {
                const $img = document.createElement('img');
                $img.classList.add('is-article-img', 'is-w100');
                $img.src = $row.images.square.small;
                $img.alt = $row.title;
                $img.loading = 'lazy';
                $figure.appendChild($img);
              }
              $div3.appendChild($figure);
            }
            $a.appendChild($div3);

            const $div4 = document.createElement('div');
            $div4.classList.add('col-xs-8', 'col-sm-9');
            {
              const $div5 = document.createElement('div');
              $div5.classList.add('title', 'is-4');
              $div5.textContent = $row.title;
              $div4.appendChild($div5);
            }

            const $div6 = document.createElement('div');
            $div6.classList.add('row', 'between-xs', 'fz-sm', 'news-article-info');
            {
              const $div7 = document.createElement('div');
              $div7.classList.add('col-xs-9');
              {
                const $time = document.createElement('time');
                $time.classList.add('c-pink');
                $time.dateTime = $row.update_date;
                $time.textContent = formatDate(new Date($row.update_date)) + ' 更新';
                $div7.appendChild($time);

                //is_new
                const $limit = Date.parse($row.update_date);
                const $current = new Date();
                $current.setHours($current.getHours() - 24);

                if ($limit >= $current.getTime()) {
                  const $span2 = document.createElement('span');
                  $span2.classList.add('label-round', 'is-new', 'is-pink', 'ml5');
                  $span2.textContent = 'NEW';
                  $div7.appendChild($span2);
                }
              }
              $div6.appendChild($div7);
            }
            $div4.appendChild($div6);

            $a.appendChild($div4);
          }
          $article.appendChild($a);
        }
        $div.appendChild($article);
      });
    }
  }
  return $div;
}

