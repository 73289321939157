import {formatDate} from '../formatDate';

export {createNewsBlock};

function createNewsBlock(data, options) {
  //気になるニュース
  if (data.articles.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('article-list', 'mb10');
    {
      data.articles.forEach($row => {
        const $a = document.createElement('a');
        $a.classList.add('row', 'is-article');
        $a.href = $row.uri;
        {
          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-4', 'is-article-thumb');
          {
            const $div4 = document.createElement('div');
            $div4.classList.add('is-article-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img', 'is-w100');
              $img.src = $row.images[2].small;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $div4.appendChild($img);
            }
            $div3.appendChild($div4);
          }
          $a.appendChild($div3);

          const $div5 = document.createElement('div');
          $div5.classList.add('col-xs-8', 'is-article-info');
          {
            const $div6 = document.createElement('div');
            $div6.classList.add('is-article-title', 'line-clamp', 'is-2');
            $div6.textContent = $row.title;
            $div5.appendChild($div6);

            const $div7 = document.createElement('div');
            $div7.classList.add('is-article-status', 'between-xs');
            {
              if ($row.additionals.length > 0) {
                const $span = document.createElement('span');
                $span.classList.add('is-category');
                $span.textContent = $row.additionals[1];
                $div7.appendChild($span);
              }
              const $span2 = document.createElement('span');
              $span2.classList.add('is-date');
              $span2.textContent = formatDate(new Date($row.open_at));
              $div7.appendChild($span2);
            }
            $div5.appendChild($div7);
          }
          $a.appendChild($div5);
        }
        $div.appendChild($a);
      });
    }
    return $div;
  }
}

