import createAPI from './APIRequest';

export {clipNewsSeriesKeyword};

const API = createAPI();
function _clipNewsSeriesKeyowrd(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  const $series = document.querySelectorAll('.js-clip-news-series-keyword');
  if($babysymbol) {
    $babysymbol.addEventListener('animationend', (event) => {
      // CSSアニメーションが終了したら必要ないのでクラスを削除する
      el.classList.remove('is-on');
      $babysymbol.classList.add('c-pink');
      $babysymbol.classList.remove('c-gray');
      $babysymbol.style.color = null;
    });
  }
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    $current.classList.toggle('is-on');
    let clipimg = document.getElementById('clip_notification');
    let isNotification = $current.dataset.clipNotification;
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        clipimg.classList.remove('is-on');
        clipimg.classList.remove('is-off');
        $current.classList.add('is-loading');
        if($babysymbol) {
          $babysymbol.classList.remove('babysymbol-clip');
        }
      },
      (response) => {
        $current.classList.remove('is-loading');
        if ($current.dataset.clipMethod === 'POST') {
          // ポップアップCSSアニメーション開始
          if(isNotification === '1'){
            clipimg.classList.add('is-on');
            clipimg.classList.remove('is-off');
          }
          $series.forEach($s => {
            // カウンター更新
            let $counter;
            let $icon;
            if ($current.dataset.clipSeriesId) {
              $counter = $s.querySelector('.clips-counter-' + $current.dataset.clipSeriesId);
              $icon = $s.querySelector('.clips-icon-' + $current.dataset.clipSeriesId);
            }
            if($counter){
              $counter.textContent = response.total_count + ' クリップ';
              $counter.parentElement.dataset.clipMethod = 'DELETE';
            }
            if($icon){
              $icon.classList.add('babysymbol-clip');
              $icon.classList.add('c-pink');
              $icon.classList.remove('c-gray');
            }
          });
        } else {
          // ポップアップCSSアニメーション開始
          if(isNotification === '1'){
            clipimg.classList.remove('is-on');
            clipimg.classList.add('is-off');
          }
          $series.forEach($s => {
            // カウンター更新
            let $counter;
            let $icon;
            if ($current.dataset.clipSeriesId) {
              $counter = $s.querySelector('.clips-counter-' + $current.dataset.clipSeriesId);
              $icon = $s.querySelector('.clips-icon-' + $current.dataset.clipSeriesId);
            }
            if($counter){
              $counter.textContent = response.total_count + ' クリップ';
              $counter.parentElement.dataset.clipMethod = 'POST';
            }
            if($icon){
              $icon.classList.add('babysymbol-clip');
              $icon.classList.add('c-gray');
              $icon.classList.remove('c-pink');
              $icon.style.color = null;
            }
          });
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        } else {
          $current.classList.remove('is-loading');
          if($babysymbol) {
            $babysymbol.classList.add('babysymbol-clip');
          }
        }
      }
    );
  });
}

async function clipNewsSeriesKeyword(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipNewsSeriesKeyowrd(el));
  }
}
