import {formatDate} from '../formatDate';

export {createNewsSeriesTagSelectorBlock};

function createNewsSeriesTagSelectorBlock(data, options, viewer) {
  //共通レイアウト　連載タグセレクター

  const $option = document.createElement('option');
  $option.textContent = '読みたいマンガのジャンルを選択';
  $option.value = '0';
  if(!options.tag){
    $option.selected = true;
  }

  viewer.appendChild($option);

  if (data.tags.length > 0) {
    data.tags.forEach($tag => {

      const $option = document.createElement('option');
      $option.textContent = $tag.name;
      $option.value = $tag.id;
      if(options.tag){
        if(options.tag === $tag.id)
        $option.selected = true;
      }
      viewer.appendChild($option);
    });

    return true;
  }
}
