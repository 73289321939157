export {createEmbedTweetBlock};
import moment from 'moment/moment'

function createEmbedTweetBlock(data, options) {

  const $div_scroll = document.createElement('div');
  $div_scroll.classList.add('tsubuyaki');
  {
  if (data.tweet_posts.length > 0) {
      data.tweet_posts.forEach($row => {
        const $div_contents = document.createElement('div');
        $div_contents.classList.add('tsubuyaki-contents');
        {
          const $div_header = document.createElement('div');
          $div_header.classList.add('tsubuyaki-header');
          {
            const $div_image = document.createElement('div');
            $div_image.classList.add('user-image');
            {
              const $img = document.createElement('img');
              $img.src = $row.user.profile_photo_path;
              $img.alt = $row.user.nickname;
              $div_image.appendChild($img);
            }
            $div_header.appendChild($div_image);

            const $div_user = document.createElement('div');
            $div_user.classList.add('app-user');
            {
              const $div_user_name = document.createElement('div');
              $div_user_name.classList.add('app-user-name');
              $div_user_name.textContent =  $row.user.nickname;
              $div_user.appendChild($div_user_name);

              const $div_user_info = document.createElement('div');
              $div_user_info.classList.add('app-user-info');
              {
                const $span_minute = document.createElement('span');
                $span_minute.classList.add('minute');

                $span_minute.textContent = getDiffTimeText($row.created_at,new Date());
                $div_user_info.appendChild($span_minute);

                const $span_id = document.createElement('span');
                $span_id.classList.add('id');
                $span_id.textContent = '@' + $row.tweet_md5_for_url;
                $div_user_info.appendChild($span_id);
              }
              $div_user.appendChild($div_user_info);
            }
            $div_header.appendChild($div_user);
          }
          $div_contents.appendChild($div_header);

          const $div_message = document.createElement('div');
          $div_message.classList.add('tsubuyaki-message');
          {
            const $p_message = document.createElement('p');
            $p_message.textContent = $row.post_text;
            $div_message.appendChild($p_message);

            const $a_more = document.createElement('a');
            $a_more.classList.add('more');
            $a_more.textContent = 'もっとみる';
            $a_more.href = 'https://baby-calendar.onelink.me/gEqy/4po4wwfn';
            $a_more.target = '_blank';
            $div_message.appendChild($a_more);
          }
          $div_contents.appendChild($div_message);

          const $div_footer = document.createElement('div');
          $div_footer.classList.add('tsubuyaki-footer');
          {
            const $span_comment = document.createElement('span');
            $span_comment.classList.add('comment');
            $span_comment.textContent = $row.retweet_count;
            $div_footer.appendChild($span_comment);

            const $span_iine = document.createElement('span');
            $span_iine.classList.add('iine');
            $span_iine.textContent = $row.nice_count;
            $div_footer.appendChild($span_iine);
          }
          $div_contents.appendChild($div_footer);
        }

        $div_scroll.appendChild($div_contents);
      });
    }
  }

  return $div_scroll;
}

function getDiffTimeText(startdate,enddate){

  var startdateMoment = moment(startdate);
  var enddateMoment = moment(enddate);

  const diff_seconds = enddateMoment.diff(startdateMoment,'seconds');
  const diff_minutes = enddateMoment.diff(startdateMoment,'minutes');
  const diff_hours = enddateMoment.diff(startdateMoment,'hours');
  const diff_days = enddateMoment.diff(startdateMoment,'days');
  const diff_months = enddateMoment.diff(startdateMoment,'months');
  const diff_years = enddateMoment.diff(startdateMoment,'years');

  var diff_text = '';
  if(diff_seconds <= 59){
    diff_text = diff_seconds + '秒前';
  }else if(diff_minutes <= 59){
    diff_text = diff_minutes + '分前';
  }else if(diff_hours <= 23){
    diff_text = diff_hours + '時間前';
  }else if(diff_days <= 30){
    diff_text = diff_days + '日前';
  }else if(diff_months <= 11){
    diff_text = diff_months + 'ヶ月前';
  }else{
    diff_text = diff_years + '年前';
  }

  return diff_text;

}
