import {formatDate} from '../formatDate';

export {createIndexHistoryBlock};

function createIndexHistoryBlock(data, options, page) {
  //ニュース履歴記事
  if (data.news.length > 0) {
    const $div_list = document.createElement('div');
    $div_list.classList.add('news-article-list');
    {
      const $div = document.createElement('div');
      $div.classList.add('row');
      {
        data.news.forEach($row => {
          const $div_col = document.createElement('div');
          $div_col.classList.add('col-xs-12');
          {
            const $article = document.createElement('article');
            $article.classList.add('news-article');
            {
              const $a = document.createElement('a');
              $a.classList.add('row','middle-xs');
              $a.href = '/smilenews/detail/' + $row.id;
              {
                const $div2 = document.createElement('div');
                $div2.classList.add('col-xs-4', 'col-sm-3', 'col-lg-5', 'is-article-thumb');
                {
                  const $figure = document.createElement('figure');
                  $figure.classList.add('is-article-img-trim');
                  {
                    const $img = document.createElement('img');
                    $img.classList.add('is-article-img');
                    $img.src = $row.thumb_image;
                    $img.alt = $row.title;
                    $img.loading = 'lazy';
                    $img.style.width = '650';
                    $img.style.height = 'auto';
                    $figure.appendChild($img);

                    const $span = document.createElement('span');
                    $span.classList.add('label-news', 'u-bg_category-' + $row.category_english_name);
                    $span.textContent = $row.category_name;
                    $figure.appendChild($span);
                  }
                  $div2.appendChild($figure);
                }
                $a.appendChild($div2);

                const $div3 = document.createElement('div');
                $div3.classList.add('col-xs-8', 'col-sm-9', 'col-lg-7');
                {
                  const $div4 = document.createElement('div');
                  $div4.classList.add('title', 'is-4');
                  $div4.textContent = $row.title;
                  $div3.appendChild($div4);
                }
                $a.appendChild($div3);
              }
              $article.appendChild($a);

              const $div6 = document.createElement('div');
              $div6.classList.add('row', 'middle-xs', 'fz-sm', 'news-article-info');
              {
                const $div7 = document.createElement('div');
                $div7.classList.add('col-xs-7');
                {
                  //open_date
                  const $time = document.createElement('time');

                  $time.dateTime = $row.open_date;
                  $time.textContent = formatDate(new Date($row.open_date));
                  $div7.appendChild($time);

                  const $div8 = document.createElement('div');
                  $div8.classList.add('display-ib', 'ml10');
                  {
                    //comments_count
                    const $comments_count = $row.comments_count;
                    if ($comments_count > 0) {
                      const $span = document.createElement('span');
                      if ($comments_count > 10) {
                        $span.classList.add('c-news-talk-red');
                      } else {
                        $span.classList.add('c-news-talk-blue');
                      }
                      {
                        const $i = document.createElement('i');
                        $i.classList.add('babysymbol', 'babysymbol-talk', 'mr5');
                        $span.appendChild($i);

                        const $span_text = document.createElement('span');
                        $span_text.textContent = $comments_count;
                        $span.appendChild($span_text);
                      }
                      $div8.appendChild($span);
                    }

                    const $clipping = $row.clipping;
                    if ($clipping > 0) {
                      const $span2 = document.createElement('span');
                      {
                        const $i2 = document.createElement('i');
                        $i2.classList.add('babysymbol', 'babysymbol-clip', 'mr5');
                        $span2.appendChild($i2);

                        const $span2_text = document.createElement('span');
                        $span2_text.textContent = $clipping;
                        $span2.appendChild($span2_text);
                      }
                      $div8.appendChild($span2);
                    }
                  }
                  $div7.appendChild($div8);
                }
                $div6.appendChild($div7);

                const $div9 = document.createElement('div');
                $div9.classList.add('col-xs', 'end-xs');
                {
                  if(options.isLogin){
                    const $button = document.createElement('button');
                    $button.classList.add('button', 'is-rounded', 'is-small', 'is-clip', 'js-clip-news', 'gtm-clip-n12');
                    $button.dataset.clipUrl = options.clipUrl;
                    if($row.is_clipped){
                      $button.dataset.clipMethod = 'DELETE';
                    }else{
                      $button.dataset.clipMethod = 'POST';
                    }
                    $button.dataset.clipBody = 'news_id=' + $row.id;
                    $button.dataset.clipLogin = options.urlLogin;
                    {
                      const $span3 = document.createElement('span');
                      $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i','mr5');
                      if($row.is_clipped){
                        $span3.classList.add('c-pink');
                      }else{
                        $span3.classList.add('c-gray');
                      }
                      $button.appendChild($span3);

                      const $span3_text = document.createElement('span');
                      $span3_text.textContent = 'クリップ';
                      $span3_text.style.marginLeft = '0';
                      $button.appendChild($span3_text);
                    }
                    $div9.appendChild($button);
                  }else{
                    const $div10 = document.createElement('div');
                    $div10.classList.add('button', 'is-rounded', 'is-small', 'is-clip');
                    {
                      const $a = document.createElement('a');
                      $a.href = options.urlLogin;
                      {
                        const $span3 = document.createElement('span');
                        $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                        if($row.is_clipped){
                          $span3.classList.add('c-pink');
                        }else{
                          $span3.classList.add('c-gray');
                        }
                        $a.appendChild($span3);

                        const $span3_text = document.createElement('span');
                        $span3_text.textContent = 'クリップ';
                        $span3_text.style.marginLeft = '0';
                        $a.appendChild($span3_text);
                      }
                      $div10.appendChild($a);
                    }
                    $div9.appendChild($div10);
                  }
                }
                $div6.appendChild($div9);
              }
              $article.appendChild($div6);
            }
            $div_col.appendChild($article);
          }
          $div.appendChild($div_col);
        });
      }
      $div_list.appendChild($div);
    }
    return $div_list;
  }
}

