import {formatDate} from '../formatDate';

export {createNewsClipsBlock};

function createNewsClipsBlock(data, options) {
  //共通レイアウト　クリップした記事
  if (data.articles.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('row');
    {
      data.articles.forEach($row => {
        const $div2 = document.createElement('div');
        $div2.classList.add('col-xs-12', 'col-lg-6');
        {
          const $article = document.createElement('article');
          $article.classList.add('news-article');
          {
            const $a = document.createElement('a');
            $a.classList.add('row', 'is-article');
            $a.href = '/smilenews/detail/' + $row.news_id;
            {
              const $div3 = document.createElement('div');
              $div3.classList.add('col-xs-4', 'col-sm-3', 'col-lg-4', 'is-article-thumb');
              {
                const $figure = document.createElement('figure');
                $figure.classList.add('is-article-img-trim');
                {
                  const $img = document.createElement('img');
                  $img.classList.add('is-article-img', 'is-w100');
                  $img.src = $row.images.square.large;
                  $img.alt = $row.title;
                  $img.loading = 'lazy';
                  $figure.appendChild($img);

                  const $span = document.createElement('span');
                  $span.classList.add('label-news', 'u-bg_category-' + $row.category_english_name);
                  $span.textContent = $row.category_name;
                  $figure.appendChild($span);
                }
                $div3.appendChild($figure);
              }
              $a.appendChild($div3);

              const $div4 = document.createElement('div');
              $div4.classList.add('col-xs-8', 'col-sm-9', 'col-lg-8');
              {
                const $div5 = document.createElement('div');
                $div5.classList.add('title', 'is-4');
                $div5.textContent = $row.title;
                $div4.appendChild($div5);

                const $div6 = document.createElement('div');
                $div6.classList.add('row', 'between-xs', 'fz-sm', 'news-article-info');
                {
                  const $div7 = document.createElement('div');
                  $div7.classList.add('col-xs-7');
                  {
                    //open_date
                    const $time = document.createElement('time');

                    $time.dateTime = $row.open_date;
                    $time.textContent = formatDate(new Date($row.open_date));
                    $div7.appendChild($time);
                  }
                  $div6.appendChild($div7);

                  const $div8 = document.createElement('div');
                  $div8.classList.add('col-xs', 'end-xs');
                  {
                    //comments_count
                    const $comments_count = $row.comments_count;
                    if ($comments_count > 0) {
                      const $span3 = document.createElement('span');
                      if ($comments_count > 10) {
                        $span3.classList.add('c-news-talk-red');
                      } else {
                        $span3.classList.add('c-news-talk-blue');
                      }
                      {
                        const $i = document.createElement('i');
                        $i.classList.add('babysymbol', 'babysymbol-talk', 'mr5');
                        $span3.appendChild($i);

                        const $span3_text = document.createElement('span');
                        $span3_text.textContent = $comments_count;
                        $span3.appendChild($span3_text);
                      }
                      $div8.appendChild($span3);
                    }

                    const $clipping = $row.clipping;
                    if ($clipping > 0) {
                      const $span4 = document.createElement('span');
                      {
                        const $i2 = document.createElement('i');
                        $i2.classList.add('babysymbol', 'babysymbol-clip', 'mr5');
                        $span4.appendChild($i2);

                        const $span4_text = document.createElement('span');
                        $span4_text.textContent = $clipping;
                        $span4.appendChild($span4_text);
                      }
                      $div8.appendChild($span4);
                    }
                  }
                  $div6.appendChild($div8);
                }
                $div4.appendChild($div6);
              }
              $a.appendChild($div4);
            }
            $article.appendChild($a);
          }
          $div2.appendChild($article);
        }
        $div.appendChild($div2);
      });
    }

    return $div;
  }
}

