import {formatDate} from '../formatDate';

export {createPickupBlock};

function createPickupBlock(data, options, viewer) {
  //共通レイアウト　pickup
  if (data.length > 0) {

    const $pickups = [];
    const $body = data.split('\n');
    shuffle($body).forEach($row => {
      const $items = $row.split(',');
      const $count = $items.length;

      if($count >= 3){
        const $p = {'title': $items[0],'url': $items[1], 'img': $items[2]};
        if($count >= 3){
          $p['target_blank'] = $items[3] ? 1 : 0;
        }

        if($count > 4 && (typeof ($items[4]) || $items[4] instanceof String) && $items[4] !== ''){
          $p['url_pcweb'] = $items[4];
        }

        if($count > 5 && (typeof ($items[5]) || $items[4] instanceof String) && $items[5] !== ''){
          $p['url_spweb'] = $items[5];
        }
        $pickups.push($p);
      }
    });

    $pickups.forEach($pickup => {
      const $li = document.createElement('li');
      $li.classList.add('swiper-slide',);
      {
        let $url = '';
        if(options.isMobile === 1){
          if('url_spweb' in $pickup){
            $url = $pickup['url_spweb'];
          }else{
            $url = $pickup['url'].replace('utm_medium=app','utm_medium=spweb');
          }
        }else{
          if('url_pcweb' in $pickup){
            $url = $pickup['url_pcweb'];
          }else{
            $url = $pickup['url'].replace('utm_medium=app','utm_medium=pcweb');
          }
        }
        const $a = document.createElement('a');
        $a.href = $url;
        if($pickup['target_blank']) {
          $a.target = '_blank';
        }
        {
          const $img = document.createElement('img');
          $img.classList.add('is-w100');
          $img.src = $pickup['img'];
          $img.alt = $pickup['title'];
          $img.loading = 'lazy';

          $a.appendChild($img);
        }
        $li.appendChild($a);
      }
      viewer[0].appendChild($li);

    });

    return true;
  }
}

const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
