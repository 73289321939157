import {formatDate} from '../formatDate';
export {htmlEscape};
export {createNewsRankingBlock};

function createNewsRankingBlock(data, options) {

    const category = options.categoryUrl;
    const $div = document.createElement('div');
    {
      const $panel1div = document.createElement('div');
      $panel1div.classList.add('panel-group');
      {
        const $div2 = document.createElement('div');
        $div2.classList.add('panel', 'tab-1', 'is-show');
        {
          if(data.access.length > 0) {
            const $ol = document.createElement('ol');
            $ol.classList.add('news-article-list', 'is-rank');
            {
              data.access.forEach($row => {
                const $li = document.createElement('li');
                {
                  const $article = document.createElement('article');
                  $article.classList.add('news-article');
                  {
                    const $a = document.createElement('a');
                    $a.classList.add('row', 'middle-xs', 'gtm-click');
                    $a.id = 'clickID-news_access_ranking_' + $row.id;
                    $a.href = '/smilenews/detail/' + $row.id;
                    {
                      const $div3 = document.createElement('div');
                      $div3.classList.add('col-xs-4', 'col-sm-3', 'is-article-thumb');
                      {
                        const $figure = document.createElement('figure');
                        $figure.classList.add('is-article-img-trim');
                        {
                          const $img = document.createElement('img');
                          $img.classList.add('is-article-img', 'is-w100');
                          $img.src = $row.thumb_image;
                          $img.loading = 'lazy';
                          $img.alt = $row.title;
                          $figure.appendChild($img);

                          const $span = document.createElement('span');
                          $span.classList.add('label-news', 'u-bg_category-' + $row.category_english_name);
                          $span.textContent = $row.category_name;
                          $figure.appendChild($span);
                        }
                        $div3.appendChild($figure);
                      }
                      $a.appendChild($div3);

                      const $div4 = document.createElement('div');
                      $div4.classList.add('col-xs-8', 'col-sm-9');
                      {
                        const $div5 = document.createElement('div');
                        $div5.classList.add('title', 'is-4');
                        $div5.textContent = $row.title;
                        $div4.appendChild($div5);
                      }
                      $a.appendChild($div4);
                    }
                    $article.appendChild($a);

                    const $div6 = document.createElement('div');
                    $div6.classList.add('row', 'middle-xs', 'fz-sm', 'news-article-info');
                    {
                      const $div7 = document.createElement('div');
                      $div7.classList.add('col-xs');
                      {
                        //open_date
                        const $time = document.createElement('time');

                        $time.dateTime = $row.open_date;
                        $time.textContent = formatDate(new Date($row.open_date));
                        $div7.appendChild($time);

                        const $div8 = document.createElement('div');
                        $div8.classList.add('display-ib', 'ml10');
                        {
                          //comments_count
                          const $comments_count = $row.comments_count;
                          if ($comments_count > 0) {
                            const $span = document.createElement('span');
                            if ($comments_count > 10) {
                              $span.classList.add('c-news-talk-red');
                            } else {
                              $span.classList.add('c-news-talk-blue');
                            }
                            {
                              const $i = document.createElement('i');
                              $i.classList.add('babysymbol', 'babysymbol-talk', 'mr5');
                              $span.appendChild($i);

                              const $span_text = document.createElement('span');
                              $span_text.textContent = $comments_count;
                              $span.appendChild($span_text);
                            }
                            $div8.appendChild($span);
                          }

                          const $clipping = $row.clipping;
                          if ($clipping > 0) {
                            const $span2 = document.createElement('span');
                            {
                              const $i2 = document.createElement('i');
                              $i2.classList.add('babysymbol', 'babysymbol-clip', 'mr5');
                              $span2.appendChild($i2);

                              const $span2_text = document.createElement('span');
                              $span2_text.textContent = $clipping;
                              $span2.appendChild($span2_text);
                            }
                            $div8.appendChild($span2);
                          }
                        }
                        $div7.appendChild($div8);
                      }
                      $div6.appendChild($div7);

                      const $div9 = document.createElement('div');
                      $div9.classList.add('col-xs', 'end-xs');
                      {
                        if (options.isLogin) {
                          const $button = document.createElement('button');
                          $button.classList.add('button', 'is-rounded', 'is-small', 'is-clip', 'js-clip-news', 'gtm-clip-n8');
                          $button.dataset.clipUrl = options.clipUrl;
                          if ($row.is_clipped) {
                            $button.dataset.clipMethod = 'DELETE';
                          } else {
                            $button.dataset.clipMethod = 'POST';
                          }
                          $button.dataset.clipBody = 'news_id=' + $row.id;
                          $button.dataset.clipLogin = options.urlLogin;
                          {
                            const $span3 = document.createElement('span');
                            $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                            if ($row.is_clipped) {
                              $span3.classList.add('c-pink');
                            } else {
                              $span3.classList.add('c-gray');
                            }
                            $button.appendChild($span3);

                            const $span3_text = document.createElement('span');
                            $span3_text.textContent = 'クリップ';
                            $span3_text.style.marginLeft = '0';
                            $button.appendChild($span3_text);
                          }
                          $div9.appendChild($button);
                        } else {
                          const $div10 = document.createElement('div');
                          $div10.classList.add('button', 'is-rounded', 'is-small', 'is-clip');
                          {
                            const $a = document.createElement('a');
                            $a.href = options.urlLogin;
                            {
                              const $span3 = document.createElement('span');
                              $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                              if ($row.is_clipped) {
                                $span3.classList.add('c-pink');
                              } else {
                                $span3.classList.add('c-gray');
                              }
                              $a.appendChild($span3);

                              const $span3_text = document.createElement('span');
                              $span3_text.textContent = 'クリップ';
                              $span3_text.style.marginLeft = '0';
                              $a.appendChild($span3_text);
                            }
                            $div10.appendChild($a);
                          }
                          $div9.appendChild($div10);
                        }
                      }
                      $div6.appendChild($div9);
                    }
                    $article.appendChild($div6);
                  }
                  $li.appendChild($article);
                }
                $ol.appendChild($li);
              });
            }
            $div2.appendChild($ol);

            const $a = document.createElement('a');
            $a.classList.add('button', 'is-fullsize', 'gtm-click');
            $a.id = 'clickID-news_access_ranking_more';
            $a.href = "/smilenews/popular/all?distance=access";
            if (category && (category !== 'all' || category !== 'newsseries')) {
              $a.href = "/smilenews/popular/" + category + "?distance=access";
            }
            $a.textContent = 'もっと見る';
            {
              const $i = document.createElement('i');
              $i.classList.add('babysymbol', 'babysymbol-arrowright', 'c-pink', 'fz-i', 'ml10');
              $a.appendChild($i);
            }
            $div2.appendChild($a);
          }else{
            const $p = document.createElement('p');
            $p.classList.add('text-center','mt20','mb20');
            $p.textContent = 'ランキング集計中です';
            $div2.appendChild($p);
          }
        }
        $panel1div.appendChild($div2);
      }

      $div.appendChild($panel1div);

      const $panel2div = document.createElement('div');
      $panel2div.classList.add('panel-group');
      {
        const $div2 = document.createElement('div');
        $div2.classList.add('panel', 'tab-2');
        {
          if(data.comment.length > 0) {
            const $ol = document.createElement('ol');
            $ol.classList.add('news-article-list', 'is-rank');
            {
              data.comment.forEach($row => {
                const $li = document.createElement('li');
                {
                  const $article = document.createElement('article');
                  $article.classList.add('news-article');
                  {
                    const $a = document.createElement('a');
                    $a.classList.add('row', 'middle-xs', 'gtm-click');
                    $a.id = 'clickID-news_comment_ranking_' + $row.id;
                    $a.href = '/smilenews/detail/' + $row.id;
                    {
                      const $div3 = document.createElement('div');
                      $div3.classList.add('col-xs-4', 'col-sm-3', 'is-article-thumb');
                      {
                        const $figure = document.createElement('figure');
                        $figure.classList.add('is-article-img-trim');
                        {
                          const $img = document.createElement('img');
                          $img.classList.add('is-article-img', 'is-w100');
                          $img.src = $row.thumb_image;
                          $img.alt = $row.title;
                          $figure.appendChild($img);

                          const $span = document.createElement('span');
                          $span.classList.add('label-news', 'u-bg_category-' + $row.category_english_name);
                          $span.textContent = $row.category_name;
                          $figure.appendChild($span);
                        }
                        $div3.appendChild($figure);
                      }
                      $a.appendChild($div3);

                      const $div4 = document.createElement('div');
                      $div4.classList.add('col-xs-8', 'col-sm-9');
                      {
                        const $div5 = document.createElement('div');
                        $div5.classList.add('title', 'is-4');
                        $div5.textContent = $row.title;
                        $div4.appendChild($div5);
                      }
                      $a.appendChild($div4);
                    }
                    $article.appendChild($a);

                    const $div6 = document.createElement('div');
                    $div6.classList.add('row', 'middle-xs', 'fz-sm', 'news-article-info');
                    {
                      const $div7 = document.createElement('div');
                      $div7.classList.add('col-xs');
                      {
                        //open_date
                        const $time = document.createElement('time');

                        $time.dateTime = $row.open_date;
                        $time.textContent = formatDate(new Date($row.open_date));
                        $div7.appendChild($time);

                        const $div8 = document.createElement('div');
                        $div8.classList.add('display-ib', 'ml10');
                        {
                          //comments_count
                          const $comments_count = $row.comments_count;
                          if ($comments_count > 0) {
                            const $span = document.createElement('span');
                            if ($comments_count > 10) {
                              $span.classList.add('c-news-talk-red');
                            } else {
                              $span.classList.add('c-news-talk-blue');
                            }
                            {
                              const $i = document.createElement('i');
                              $i.classList.add('babysymbol', 'babysymbol-talk', 'mr5');
                              $span.appendChild($i);

                              const $span_text = document.createElement('span');
                              $span_text.textContent = $comments_count;
                              $span.appendChild($span_text);
                            }
                            $div8.appendChild($span);
                          }

                          const $clipping = $row.clipping;
                          if ($clipping > 0) {
                            const $span2 = document.createElement('span');
                            {
                              const $i2 = document.createElement('i');
                              $i2.classList.add('babysymbol', 'babysymbol-clip', 'mr5');
                              $span2.appendChild($i2);

                              const $span2_text = document.createElement('span');
                              $span2_text.textContent = $clipping;
                              $span2.appendChild($span2_text);
                            }
                            $div8.appendChild($span2);
                          }
                        }
                        $div7.appendChild($div8);
                      }
                      $div6.appendChild($div7);

                      const $div9 = document.createElement('div');
                      $div9.classList.add('col-xs', 'end-xs');
                      {
                        if (options.isLogin) {
                          const $button = document.createElement('button');
                          $button.classList.add('button', 'is-rounded', 'is-small', 'is-clip', 'js-clip-news', 'gtm-clip-n9');
                          $button.dataset.clipUrl = options.clipUrl;
                          if ($row.is_clipped) {
                            $button.dataset.clipMethod = 'DELETE';
                          } else {
                            $button.dataset.clipMethod = 'POST';
                          }
                          $button.dataset.clipBody = 'news_id=' + $row.id;
                          $button.dataset.clipLogin = options.urlLogin;
                          {
                            const $span3 = document.createElement('span');
                            $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                            if ($row.is_clipped) {
                              $span3.classList.add('c-pink');
                            } else {
                              $span3.classList.add('c-gray');
                            }
                            $button.appendChild($span3);

                            const $span3_text = document.createElement('span');
                            $span3_text.textContent = 'クリップ';
                            $span3_text.style.marginLeft = '0';
                            $button.appendChild($span3_text);
                          }
                          $div9.appendChild($button);
                        } else {
                          const $div10 = document.createElement('div');
                          $div10.classList.add('button', 'is-rounded', 'is-small', 'is-clip');
                          {
                            const $a = document.createElement('a');
                            $a.href = options.urlLogin;
                            {
                              const $span3 = document.createElement('span');
                              $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                              if ($row.is_clipped) {
                                $span3.classList.add('c-pink');
                              } else {
                                $span3.classList.add('c-gray');
                              }
                              $a.appendChild($span3);

                              const $span3_text = document.createElement('span');
                              $span3_text.textContent = 'クリップ';
                              $span3_text.style.marginLeft = '0';
                              $a.appendChild($span3_text);
                            }
                            $div10.appendChild($a);
                          }
                          $div9.appendChild($div10);
                        }
                      }
                      $div6.appendChild($div9);
                    }
                    $article.appendChild($div6);

                    if($row.comments.length > 0) {
                      const $comment_div = document.createElement('div');
                      $comment_div.classList.add('p-comments-block', 'fukidashi-block');
                      {
                        const $ul = document.createElement('ul');
                        $ul.classList.add('p-comments','fukidashi');
                        $row.comments.forEach($each_comment => {
                          const $li = document.createElement('li');
                          $li.classList.add('p-comment','comment_key');
                          $li.setAttribute('id','comment_key' + $each_comment.id);
                          {
                            const $time_div = document.createElement('div');
                            $time_div.classList.add('p-comment-state-top');
                            {
                              const $time = document.createElement('time');
                              $time.classList.add('p-comment-state-top');
                              $time.setAttribute('datetime', $each_comment.created_at);
                              $time.textContent = $each_comment.created_at;
                              $time_div.appendChild($time);
                            }
                            $li.appendChild($time_div);

                            let comment = $each_comment.contents;
                            if (comment.length > 100) {
                              const $comment_digest = document.createElement('div');
                              $comment_digest.classList.add('p-comment-text','p-comment-text-digest');
                              let part_comment = comment.substr(0, 100);
                              $comment_digest.style.overflowWrap = 'break-word';
                              $comment_digest.textContent = htmlEscape(part_comment);
                              {
                                const $a = document.createElement('a');
                                $a.classList.add('link','is-text','js-btn-readmore');
                                $a.textContent = 'もっと見る';
                                $comment_digest.appendChild($a);
                              }
                              $li.appendChild($comment_digest);

                              const $comment_full = document.createElement('div');
                              $comment_full.classList.add('p-comment-text','p-comment-text-body');
                              $comment_full.style.overflowWrap = 'break-word';
                              comment = htmlEscape(comment);
                              comment = comment.replace(/\r\n/g, "<br />");
                              comment = comment.replace(/(\n|\r)/g, "<br />");
                              $comment_full.innerHTML = comment;
                              $li.appendChild($comment_full);
                            }else {
                              const $comment = document.createElement('div');
                              $comment.classList.add('p-comment-text');
                              $comment.style.overflowWrap = 'break-word';
                              comment = htmlEscape(comment);
                              comment = comment.replace(/\r\n/g, "<br />");
                              comment = comment.replace(/(\n|\r)/g, "<br />");
                              $comment.innerHTML = comment;
                              $li.appendChild($comment);
                            }
                          }
                          $ul.appendChild($li);
                        });
                        $comment_div.appendChild($ul);
                      }
                      $article.appendChild($comment_div);
                    }
                  }
                  $li.appendChild($article);
                }
                $ol.appendChild($li);
              });
            }
            $div2.appendChild($ol);

            const $a = document.createElement('a');
            $a.classList.add('button', 'is-fullsize', 'gtm-click');
            $a.id = 'clickID-news_comment_ranking_more';
            $a.href = "/smilenews/popular/all?distance=comment";
            if (category && (category !== 'all' || category !== 'newsseries')) {
              $a.href = "/smilenews/popular/" + category + "?distance=comment";
            }
            $a.textContent = 'もっと見る';
            {
              const $i = document.createElement('i');
              $i.classList.add('babysymbol', 'babysymbol-arrowright', 'c-pink', 'fz-i', 'ml10');
              $a.appendChild($i);
            }
            $div2.appendChild($a);
          }else{
            const $p = document.createElement('p');
            $p.classList.add('text-center','mt20','mb20');
            $p.textContent = '最近コメントがついた記事はありません';
            $div2.appendChild($p);
          }
        }
        $panel1div.appendChild($div2);
      }
      $div.appendChild($panel2div);
    }
    return $div;
}

function htmlEscape(str) {
  if (!str) return;
  return str.replace(/[<>&"'`]/g, function(match) {
    const escape = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      "'": '&#39;',
      '`': '&#x60;'
    };
    return escape[match];
  });
}
