
export {createDetailVideoBlock};

function createDetailVideoBlock(data, options) {
  //見ておきたい動画
  if (data.articles.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('row');
    {
      data.articles.forEach($row => {
        const $div2 = document.createElement('div');
        $div2.classList.add('col-xs-6', 'col-sm-3');
        {
          const $movielink = document.createElement('a');
          $movielink.classList.add('row', 'is-article');
          $movielink.href = $row.uri;
          {
            const $div3 = document.createElement('div');
            $div3.classList.add('col-xs-12', 'is-article-thumb');
            {
              const $div4 = document.createElement('div');
              $div4.classList.add('is-article-img-trim');
              {
                const $img = document.createElement('img');
                $img.classList.add('is-article-img', 'is-w100');
                $img.src = $row.video_images[2].small;
                $img.alt = $row.title;
                $img.loading = 'lazy';
                $div4.appendChild($img);
              }
              $div3.appendChild($div4);
            }
            $movielink.appendChild($div3);

            const $info = document.createElement('div');
            $info.classList.add('col-xs-12', 'is-article-info');
            {
              const $article = document.createElement('div');
              $article.classList.add('is-article-title', 'mb10');
              $article.textContent = $row.title;
              $info.appendChild($article);

              const $articledesc = document.createElement('div');
              $articledesc.classList.add('line-clamp', 'is-3');
              $articledesc.textContent = $row.description;
              $info.appendChild($articledesc);
            }
            $movielink.appendChild($info);
          }
          $div2.appendChild($movielink);
        }
        $div.appendChild($div2);
      });
    }
    return $div;
  }
}

