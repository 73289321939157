export {createIndexNewsInformationBlock};

function createIndexNewsInformationBlock(data, options, viewer) {
  //インフォメーション
  if (data.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('mb10');
    {
      $div.innerHTML = decodeURI(data);
    }

    return $div;
  }
}

