
export {createQandaListBlock};

function createQandaListBlock(data, options) {
  if (data.qanda.length > 0) {
    const $div = document.createElement('div');
    {
      data.qanda.forEach($row => {
        const $qblock = document.createElement('div');
        $qblock.classList.add('button-flex', 'fw-bold', 'element-creators-row');
        {
          const $qsign = document.createElement('span');
          $qsign.classList.add('babysymbol','babysymbol-question','fz-i','mr10','c-green');
          $qblock.appendChild($qsign);

          const $qlink = document.createElement('a');
          $qlink.href = '/knowledge/qanda/' + options.tag + '/' + $row.id;
          $qlink.textContent = $row.title;
          $qblock.appendChild($qlink);
        }
        $div.appendChild($qblock);
      });
      const $br = document.createElement('br');
      $div.appendChild($br);
    }
    return $div;
  }
}

