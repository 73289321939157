import Masonry from "masonry-layout";

export {grid};

function grid(){
  const grids = document.querySelectorAll('.js-knowledge-grid');
  // arrayして
  const gridArr = Array.prototype.slice.call(grids);
  // masonryする
  gridArr.forEach((grid) => {
    const msnry = new Masonry( grid, {
      // options
      itemSelector: '.knowledge-grid-item',
      columnWidth: '.knowledge-grid-sizer',
      percentPosition: true
    });
  });
}
