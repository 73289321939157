
export {createKeywordListBlock};

function createKeywordListBlock(data, options) {
  const $keywords = data.items;
  if ($keywords.length > 0) {
      const $key = document.createElement('div');
      $key.classList.add('mb10');
      {
        for (let i = 0; i < $keywords.length ; i++) {
          const $link = document.createElement('a');
          $link.classList.add('link', 'is-box', 'is-blue');
          $link.href = $keywords[i].url;
          $link.textContent = $keywords[i].label;
          $key.appendChild($link);
      }
    }
    return $key;
  }
}

