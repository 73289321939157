import {formatDate} from '../formatDate';
import {htmlEscape} from './createNewsRankingBlock';

export {createSeriesKeywordRankingBlock};

function createSeriesKeywordRankingBlock(data, options) {

  const $div = document.createElement('div');
  {
    const $div1 = document.createElement('div');
    $div1.classList.add('panel', 'tab-2');
    {
      if(data.ranking.length > 0){
        const $ol = document.createElement('div');
        $ol.classList.add('news-article-list');
        {
          data.ranking.forEach($row => {
            const $title = document.createElement('div');
            $title.classList.add('row', 'mb10', 'has-gutter', 'middle-xs');
            {
              const $div2 = document.createElement('div');
              $div2.classList.add('col-xs');
              {
                const $h1 = document.createElement('h1');
                $h1.classList.add('title', 'is-1', 'has-icon');
                {
                  const $i = document.createElement('i');
                  $i.classList.add('babysymbol', 'babysymbol-news', 'mr10', 'c-pink');
                  $h1.appendChild($i);
                }
                $h1.textContent = $row.title;
                $div2.appendChild($h1);
              }
              $title.appendChild($div2);
            }
            $ol.appendChild($title);

            const $detail = document.createElement('div');
            $detail.classList.add('row', 'has-gutter', 'news-article', 'mb20');
            {
              const $thumb = document.createElement('div');
              $thumb.classList.add('col-xs-4');
              if($row.manga_flag){
                const $img1 = document.createElement('div');
                $img1.classList.add('is-article-thumb', 'is-square');
                {
                  const $figure = document.createElement('figure');
                  $figure.classList.add('is-article-img-trim');
                  {
                    const $img = document.createElement('img');
                    $img.classList.add('is-article-img');
                    $img.src = $row.images.square.large;
                    $img.alt = $row.title;
                    $img.loading = 'lazy';
                    $img.style.width = '100%';
                    $img.style.height = 'auto';
                    $figure.appendChild($img);
                  }
                  $img1.appendChild($figure);
                }
                $thumb.appendChild($img1);
              }else {
                const $img2 = document.createElement('div');
                $img2.classList.add('is-article-thumb');
                $img2.style.borderStyle = '1px solid #f3f3f3';
                {
                  const $figure2 = document.createElement('figure');
                  $figure2.classList.add('is-article-img-trim');
                  $figure2.style.borderStyle = 'none';
                  {
                    const $img3 = document.createElement('img');
                    $img3.classList.add('is-article-img');
                    $img3.src = $row.images.thumb.large;
                    $img3.alt = $row.title;
                    $img3.loading = 'lazy';
                    $img3.style.width = '100%';
                    $img3.style.height = 'auto';
                    $figure2.appendChild($img3);
                  }
                  $img2.appendChild($figure2);
                }
                $thumb.appendChild($img2);
              }
              $detail.appendChild($thumb);

              const $info = document.createElement('div');
              $info.classList.add('col-xs-8');
              {
                const $status = document.createElement('div');
                $status.classList.add('news-article-info', 'mb5');
                {
                  if($row.manga_flag){
                    if ($row.status === 0) {
                      const $div6 = document.createElement('div');
                      $div6.classList.add('label-round', 'is-palepink', 'mr10');
                      $div6.textContent = '連載中';
                      $status.appendChild($div6);

                      const $time = document.createElement('time');
                      $time.classList.add('c-pink');
                      $time.dateTime = $row.update_date;
                      $time.textContent = formatDate(new Date($row.update_date), 'date') + ' 更新';
                      $status.appendChild($time);
                    } else {
                      const $div6 = document.createElement('div');
                      $div6.classList.add('label-round', 'is-palegreen', 'mr10');
                      $div6.textContent = '完結';
                      $status.appendChild($div6);
                    }
                  }else {
                    const $time = document.createElement('time');
                    $time.classList.add('c-pink');
                    $time.dateTime = $row.update_date;
                    $time.textContent = formatDate(new Date($row.update_date), 'date') + ' 更新';
                    $status.appendChild($time);
                  }
                }
                $info.appendChild($status);


                const $p = document.createElement('p');
                $p.classList.add('mb10');
                $p.textContent = $row.description;
                $info.appendChild($p);

                if($row.manga_flag){
                  const $index = document.createElement('div');
                  $index.classList.add('row');
                  {
                    const $first = document.createElement('div');
                    $first.classList.add('col-xs-12', 'col-sm-6', 'mb10');
                    {
                      const $a1 = document.createElement('a');
                      $a1.classList.add('button', 'is-primary', 'is-fullsize');
                      $a1.href = '';
                      if($row.news_index.first){
                        $a1.href = '/smilenews/detail/' + $row.news_index.first.id;
                      }
                      $a1.textContent = '1話目から読む';
                      $first.appendChild($a1);
                    }
                    $index.appendChild($first);

                    const $last = document.createElement('div');
                    $last.classList.add('col-xs-12', 'col-sm-6');
                    {
                      const $a2 = document.createElement('a');
                      $a2.classList.add('button', 'is-primary', 'is-outlined', 'is-fullsize');
                      $a2.href = '';
                      if($row.news_index.last){
                        $a2.href = '/smilenews/detail/' + $row.news_index.last.id;
                      }
                      $a2.textContent = '1話目から読む';
                      $last.appendChild($a2);
                    }
                    $index.appendChild($last);

                  }
                  $info.appendChild($index);
                }

              }
              $detail.appendChild($info);

              const $clip = document.createElement('div');
              $clip.classList.add('col-xs-12', 'mt10');
              {
                const $notice = document.createElement('div');
                $notice.classList.add('row', 'middle-xs', 'around-xs', 'gutter-around-half');
                {
                  const $notice1 = document.createElement('div');
                  $notice1.classList.add('col-sm-8', 'col-xs-7', 'balloon-right');
                  $notice1.textContent = '最新話が配信されたら、メールやプッシュ通知でお知らせ！';
                  $notice.appendChild($notice1);

                  if(options.isLogin){
                    const $button = document.createElement('div');
                    $button.classList.add('col-sm-3', 'col-xs-4', 'button', 'is-rounded', 'is-small', 'is-clip', 'js-clip-news-series-keyword', 'gtm-clip-ns1');
                    $button.dataset.clipUrl = options.clipUrl;
                    if($row.is_clipped){
                      $button.dataset.clipMethod = 'DELETE';
                    }else{
                      $button.dataset.clipMethod = 'POST';
                    }
                    $button.dataset.clipBody = 'news_series_id=' + $row.id;
                    $button.dataset.clipLogin = options.urlLogin;
                    $button.dataset.clipNotification = '1';
                    $button.dataset.clipSeriesId = $row.id;
                    {
                      const $span3 = document.createElement('span');
                      $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5', 'clips-icon-' + $row.id );
                      if($row.is_clipped){
                        $span3.classList.add('c-pink');
                      }else{
                        $span3.classList.add('c-gray');
                      }
                      $button.appendChild($span3);

                      const $span3_text = document.createElement('span');
                      $span3_text.classList.add('clips-counter-' + $row.id);
                      $span3_text.textContent = $row.clipping + ' クリップ';
                      $span3_text.style.marginLeft = '0';
                      $button.appendChild($span3_text);
                    }
                    $notice.appendChild($button);
                  }else{
                    const $div10 = document.createElement('div');
                    $div10.classList.add('button', 'is-rounded', 'is-small', 'is-clip');
                    {
                      const $a = document.createElement('a');
                      $a.href = options.urlLogin;
                      {
                        const $span3 = document.createElement('span');
                        $span3.classList.add('babysymbol', 'babysymbol-clip', 'fz-i', 'mr5');
                        if($row.is_clipped){
                          $span3.classList.add('c-pink');
                        }else{
                          $span3.classList.add('c-gray');
                        }
                        $a.appendChild($span3);

                        const $span3_text = document.createElement('span');
                        $span3_text.textContent = $row.clipping + ' クリップ';
                        $span3_text.style.marginLeft = '0';
                        $a.appendChild($span3_text);
                      }
                      $div10.appendChild($a);
                    }
                    $notice.appendChild($div10);
                  }

                }
                $clip.appendChild($notice);
              }
              $detail.appendChild($clip);
            }
            $ol.appendChild($detail);
          });
        }
        $div1.appendChild($ol);
      }else{
        const $p = document.createElement('p');
        $p.classList.add('text-center','mt20','mb20');
        $p.textContent = 'ランキング集計中です';
        $div1.appendChild($p);
      }
    }
    $div.appendChild($div1);
  }

  return $div;

}

