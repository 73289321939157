import {formatDate} from '../formatDate';

export {createIndexNewsSeriesNominationBlock};

function createIndexNewsSeriesNominationBlock(data, options) {

  if(data.news_series.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('news-article-list');
    {
      data.news_series.forEach($row => {
      const $article = document.createElement('article');
      $article.classList.add('news-article');
      {
        const $a = document.createElement('a');
        $a.classList.add('row');
        $a.href = '/smilenews/newsseries/detail/' + $row.id;
        {
          const $div2 = document.createElement('div');
          $div2.classList.add('col-xs-4', 'col-sm-3', 'is-article-thumb', 'is-square');
          {
            const $figure = document.createElement('figure');
            $figure.classList.add('is-article-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img', 'is-w100');
              $img.src = $row.images.square.medium;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $figure.appendChild($img);
            }
            $div2.appendChild($figure);
          }
          $a.appendChild($div2);

          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-8', 'col-sm-9');
          {
            const $div4 = document.createElement('div');
            $div4.classList.add('title', 'is-4', 'mb5');
            $div4.textContent = $row.title;
            $div3.appendChild($div4);

            const $div5 = document.createElement('div');
            $div5.classList.add('fz-sm', 'news-article-info', 'mb10');
            {
              if ($row.status === 0) {
                const $div6 = document.createElement('div');
                $div6.classList.add('label-round', 'is-palepink', 'mr10');
                $div6.textContent = '連載中';
                $div5.appendChild($div6);

                //open_date
                const $time = document.createElement('time');
                $time.classList.add('c-pink');
                $time.dateTime = $row.update_date;
                $time.textContent = formatDate(new Date($row.update_date), 'date') + ' 更新';
                $div5.appendChild($time);
              } else {
                const $div6 = document.createElement('div');
                $div6.classList.add('label-round', 'is-palegreen', 'mr10');
                $div6.textContent = '完結';
                $div5.appendChild($div6);
              }
            }
            $div3.appendChild($div5);

            if ($row.keywords.length > 0) {
              const $div7 = document.createElement('div');
              $div7.classList.add('fz-sm');
              {
                $row.keywords.forEach($keyword => {
                  const $span = document.createElement('span');
                  $span.classList.add('link', 'is-icon');
                  {
                    const $icon = document.createElement('span');
                    $icon.classList.add('babysymbol', 'babysymbol-tag', 'mr5', 'c-pink');
                    $span.appendChild($icon);

                    const $span_text = document.createElement('span');
                    $span_text.textContent = $keyword.name;
                    $span.appendChild($span_text);
                  }
                  $div7.appendChild($span);
                });
              }
              $div3.appendChild($div7);
            }
          }
          $a.appendChild($div3);
        }
        $article.appendChild($a);
      }
      $div.appendChild($article);
    });
    }

    return $div;
  }
}

