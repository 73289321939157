function APIRequest() {
  this.callback;
  this.error;
  this.httpRequest = new XMLHttpRequest();
  if (this.httpRequest) {
    this.httpRequest.addEventListener('load', (e) => {
      if (e.currentTarget.status === 200) {
        this.callback && this.callback(e.currentTarget.response);
      } else {
        this.error && this.error(e.currentTarget.status, e.currentTarget.response); // 内部エラー
      }
    });
    this.httpRequest.addEventListener('error', () => this.error && this.error('error')); // 通信エラー
    this.httpRequest.addEventListener('abort', () => this.error && this.error('abort')); // 処理中止
  }
}
APIRequest.prototype._send = function (url, method, auth, body) {
  if (auth && auth.length > 4) {
    const basic = atob(auth.replace('Basic ', '')).split(':');
    this.httpRequest.open(method, url, true, basic[0], basic[1]);
    this.httpRequest.withCredentials = true;
    this.httpRequest.setRequestHeader('Authorization', auth);
  } else if (auth === 'none') {
    this.httpRequest.open(method, url);
  } else {
    this.httpRequest.open(method, url);
    this.httpRequest.withCredentials = true;
  }
  this.httpRequest.responseType = 'json';
  if (body instanceof FormData) {
    if (method === 'POST' || method === 'GET') {
      // Content-Type 指定不要
    } else {
      const entries = body.entries();
      body = {};
      for (const pair of entries) {
        body[pair[0]] = pair[1];
      }
      body = JSON.stringify(body);
      this.httpRequest.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    }
  } else if (body && typeof body === 'object') {
    body = JSON.stringify(body);
    this.httpRequest.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
  } else {
    this.httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
  }
  this.httpRequest.send(body);
};
APIRequest.prototype.send = function (url, method, auth, body, beforeSend, callback, error) {
  if (this.httpRequest) {
    if (this.httpRequest.readyState === XMLHttpRequest.UNSENT || this.httpRequest.readyState === XMLHttpRequest.DONE) {
      this.callback = callback;
      this.error = error;
      beforeSend && beforeSend();
      this._send(url, method, auth, body);
    }
  }
};

export default function create() {
  return new APIRequest();
}
