

export {createFeatureBlock};

function createFeatureBlock(data, options) {
  //特徴
  if ((data.feature != null)) {
      const $div = document.createElement('div');
      $div.classList.add('box-border', 'is-knowledge-pickup', 'mb20');
      {
        const $title = document.createElement('div');
        $title.classList.add('title', 'is-2', 'is-knowledge-pickup-title');
        $title.textContent = data.feature.title + 'はどんな時期？';
        $div.appendChild($title);

        const $block = document.createElement('div');
        $block.classList.add('row', 'is-knowledge-pickup-contents');
        {
          const $div2 = document.createElement('div');
          $div2.classList.add('col-xs-3');
          {
            const $div3 = document.createElement('div');
            $div3.classList.add('is-knowledge-pickup-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-knowledge-pickup-img', 'is-w100');
              $img.src = data.feature.images[2].small;
              $img.alt = data.feature.description;
              $img.loading = 'lazy';
              $div3.appendChild($img);
            }
            $div2.appendChild($div3);
          }
          $block.appendChild($div2);

          const $desc = document.createElement('div');
          $desc.classList.add('col-xs-9');
          {
            const $p = document.createElement('p');
            $p.textContent = data.feature.description;
            $desc.appendChild($p);
          }
          $block.appendChild($desc);
        }
        $div.appendChild($block);

        const $more = document.createElement('div');
        $more.classList.add('text-right');
        {
          let link = data.feature.uri;
          const $link = document.createElement('a');
          $link.classList.add('link', 'is-arrow');
          $link.href = link;
          $link.textContent = data.feature.title + 'について詳しく見る';
          {
            const $span = document.createElement('span');
            $span.classList.add('babysymbol', 'babysymbol-arrowright', 'fz-i', 'ml5');
            $link.appendChild($span);
          }
          $more.appendChild($link);
        }
        $div.appendChild($more);
      }
    return $div;
  }
}
