import Swiper from "swiper";

export {readFullComment};
function _readFullComment(el) {
  el.addEventListener('click', (event) => {
    const cl = el.closest('li');
    cl.classList.toggle('is-readmore-open');

    const options = {
      spaceBetween: 30,
      slideActiveClass: 'swiper-slide-active',
      autoHeight: true,
    };
    new Swiper('.swiper-slide-active',options);
  });

}
async function readFullComment(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _readFullComment(el));
  }
}



