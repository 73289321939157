export {createActiveVotesBlock};
import {htmlEscape} from './createNewsRankingBlock';
function createActiveVotesBlock(data, options) {

  const $div = document.createElement('div');
  {
    if (data.questions.length > 0) {
      data.questions.forEach($row => {
        if ($row.status === 1) {
          const $panel1div = document.createElement('div');
          $panel1div.classList.add('ballot-card','is-before');
          {
            const $info = document.createElement('div');
            $info.classList.add('ballot-card-info');
            {
              const $span = document.createElement('span');
              $span.classList.add('ballot-tag','is-before');
              $span.textContent = '投票受付中';
              $info.appendChild($span);

              const $question = document.createElement('h3');
              $question.classList.add('ballot-title','is-before');
              let question = htmlEscape($row.question);
              question = question.replace(/\r\n/g, "<br />");
              question = question.replace(/(\n|\r)/g, "<br />");
              question = question.replace(/　/g, "<br />");
              $question.innerHTML = question;
              $info.appendChild($question);
            }
            $panel1div.appendChild($info);

            const $detail = document.createElement('div');
            $detail.classList.add('ballot-card-detail');
            {
              $row.answers.forEach(($each_answer,index) => {
                const $answer = document.createElement('a');
                $answer.classList.add('ballot-btn','js-clip-answer', 'gtm-click');
                $answer.id = 'clickID-vote_question_' + $row.id + '_' + index;
                $answer.dataset.voteUrl = options.vote_url;
                $answer.dataset.voteMethod = 'POST';
                $answer.dataset.voteAuth = options.vote_auth;
                $answer.dataset.voteQuestionId = $row.id;
                $answer.dataset.voteAccessToken = options.access_token;
                $answer.dataset.voteBody = 'vote_question_id=' + $row.id + '&answer=' + encodeURIComponent($each_answer.answer) + '&access_token=' + options.access_token ;
                {
                  const $ans_span = document.createElement('span');
                  $ans_span.classList.add('ballot-btn-text');
                  $ans_span.textContent = $each_answer.answer;
                  $answer.appendChild($ans_span);
                }
                $detail.appendChild($answer);
              });
              const $notes = document.createElement('p');
              $notes.classList.add('ballot-notes','is-right');
              $notes.textContent = $row.close_date + 'まで';
              $detail.appendChild($notes);
            }
            $panel1div.appendChild($detail);
          }
          $div.appendChild($panel1div);

        }else if($row.status === 2) {
          const $panel2div = document.createElement('div');
          $panel2div.classList.add('ballot-card','is-after');
          {
            const $info = document.createElement('div');
            $info.classList.add('ballot-card-info');
            {
              const $span = document.createElement('span');
              $span.classList.add('ballot-tag','is-after');
              $span.textContent = '回答済';
              $info.appendChild($span);

              const $question = document.createElement('h3');
              $question.classList.add('ballot-title','is-after');
              $question.textContent = $row.question;
              $info.appendChild($question);
            }
            $panel2div.appendChild($info);

            const $detail = document.createElement('div');
            $detail.classList.add('ballot-card-detail');
            {
              const $p = document.createElement('p');
              $p.classList.add('ballot-notes');
              $p.textContent = 'あなたの回答：' + $row.my_vote_answer;
              $detail.appendChild($p);

              $row.answers.forEach($each_answer => {
                const $graph = document.createElement('div');
                $graph.classList.add('ballot-graph');
                {
                  const $ans_span = document.createElement('span');
                  $ans_span.classList.add('ballot-graph-text');
                  $ans_span.textContent = $each_answer.answer;
                  $graph.appendChild($ans_span);

                  const $percent_span = document.createElement('span');
                  $percent_span.classList.add('ballot-graph-number');
                  $percent_span.setAttribute('data-percentage', $each_answer.vote_percent + '%');
                  $graph.appendChild($percent_span);
                }
                $detail.appendChild($graph);
              });
              const $notes = document.createElement('p');
              $notes.classList.add('ballot-notes','is-right');
              $notes.textContent = $row.total_vote_count + '票・' + $row.close_date + 'まで';
              $detail.appendChild($notes);
            }
            $panel2div.appendChild($detail);

          }
          $div.appendChild($panel2div);
        }
      });
    }
  }
  return $div;

}

