
export {createRecipeBlock};

function createRecipeBlock(data, options) {
  //取り入れたいレシピ
  if (data.articles.length > 0) {
    const $recipe = document.createElement('div');
    $recipe.classList.add('recipe-list', 'mb10');
    {
      const $div = document.createElement('div');
      $div.classList.add('row');
      {
        data.articles.forEach($row => {
          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-6');
          {
            const $recipelink = document.createElement('a');
            $recipelink.classList.add('is-recipe');
            $recipelink.href = $row.uri;
            {
              const $div4 = document.createElement('div');
              $div4.classList.add('is-recipe-img-trim');
              {
                const $img = document.createElement('img');
                $img.classList.add('is-recipe-img', 'is-w100');
                $img.src = $row.images[2].small;
                $img.alt = $row.title;
                $img.loading = 'lazy';
                $div4.appendChild($img);
              }
              $recipelink.appendChild($div4);

              const $info = document.createElement('div');
              $info.classList.add('is-recipe-info');
              {
                const $div5 = document.createElement('div');
                $div5.classList.add('is-recipe-title', 'line-clamp', 'is-2');
                $div5.textContent = $row.title;
                $info.appendChild($div5);

                if ($row.additionals.length > 0) {
                  const $div6 = document.createElement('div');
                  $div6.classList.add('is-recipe-author');
                  $div6.textContent = 'by ' + $row.additionals[1];
                  $info.appendChild($div6);
                }
              }
              $recipelink.appendChild($info);
            }
            $div3.appendChild($recipelink);
          }
          $div.appendChild($div3);
        });
      }
      $recipe.appendChild($div);
    }
    return $recipe;
  }
}

