import {formatDate} from '../formatDate';

export {createIndexNewsSeriesBlock};

function createIndexNewsSeriesBlock(data, options) {
  //ニューストップ　連載
  if (data.news_series.length > 0) {

    const $ol = document.createElement('ol');
    $ol.classList.add('row', 'news-article-list');
    {
      data.news_series.forEach($row => {
        const $li = document.createElement('li');
        $li.classList.add('col-xs-6', 'col-sm-4');
        {
          const $article = document.createElement('article');
          $article.classList.add('news-article');
          {
            const $a = document.createElement('a');
            $a.href = '/smilenews/newsseries/detail/' + $row.id;
            {
              const $div2 = document.createElement('div');
              $div2.classList.add('is-article-thumb', 'is-square');
              {
                const $figure = document.createElement('figure');
                $figure.classList.add('is-article-img-trim');
                {
                  const $img = document.createElement('img');
                  $img.classList.add('is-article-img', 'is-w100');
                  $img.src = $row.images.square.medium;
                  $img.alt = $row.title;
                  $img.loading = 'lazy';
                  $figure.appendChild($img);
                }
                $div2.appendChild($figure);
              }
              $a.appendChild($div2);

              const $div3 = document.createElement('div');
              $div3.classList.add('fw-bold', 'line-clamp', 'is-1');
              $div3.textContent = $row.title;
              $a.appendChild($div3);

              const $div4 = document.createElement('div');
              $div4.classList.add('news_article-info');
              {
                const $div5 = document.createElement('div');
                $div5.classList.add('fz-sm', 'line-clamp', 'is-1');
                $div5.textContent = $row.author.name;
                $div4.appendChild($div5);
              }
              $a.appendChild($div4);
            }
            $article.appendChild($a);
          }
          $li.appendChild($article);
        }
        $ol.appendChild($li);
      });
    }

    return $ol;
  }
}

