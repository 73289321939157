export {formatDate};

const monthNames = ['01','02','03','04','05','06','07','08','09','10','11','12'];
function formatDate(date,type='datetime') {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  if(type === 'datetime') {
    return year + '/' + monthNames[monthIndex] + '/' + ('0' + day).slice(-2) + ' ' + hours + ':' + ('0' + minutes).slice(-2);
  }else{
    return year + '/' + monthNames[monthIndex] + '/' + ('0' + day).slice(-2);
  }
}
