export {addBallotPercent};

// 投票箱のパーセンテージ
function addBallotPercent() {
  // クラス名が'ballot-graph-number'の要素を取得します
  const graphPercentages = document.querySelectorAll('.ballot-graph-number');

// 各要素に対して、data-percentage属性を読み取り、--percentage CSS変数を設定します
  graphPercentages.forEach((graphPercentage) => {
    const percentage = graphPercentage.getAttribute('data-percentage');
    graphPercentage.style.setProperty('--percentage', percentage);
  });

  // グラフのアニメーション
  var graphs = document.querySelectorAll('.ballot-card');

  var observer = new IntersectionObserver((entries, observer) => {
    // 監視対象で変化があったものすべてが entries に入ってくる
    entries.forEach(entry => {
      var target = entry.target;
      if (entry.isIntersecting) {
        target.classList.add('is-in'); // .is-inクラスを追加する
      }
    });
  });

  // すべての要素を監視対象にする
  Array.from(graphs).forEach($elm => {
    observer.observe($elm);
  });
}
