
export {createVideoBlock};

function createVideoBlock(data, options) {
  //見ておきたい動画
  if (data.articles.length > 0) {
    const $div = document.createElement('div');
    $div.classList.add('article-list', 'mb10');
    {
      data.articles.forEach($row => {
        const $movielink = document.createElement('a');
        $movielink.classList.add('row', 'is-article');
        $movielink.href = $row.uri;
        {
          const $div3 = document.createElement('div');
          $div3.classList.add('col-xs-4', 'is-article-thumb');
          {
            const $div4 = document.createElement('div');
            $div4.classList.add('is-article-img-trim');
            {
              const $img = document.createElement('img');
              $img.classList.add('is-article-img', 'is-w100');
              $img.src = $row.video_images[2].small;
              $img.alt = $row.title;
              $img.loading = 'lazy';
              $div4.appendChild($img);
            }
            $div3.appendChild($div4);
          }
          $movielink.appendChild($div3);

          const $title = document.createElement('div');
          $title.classList.add('col-xs-8', 'is-article-info');
          {
            const $article = document.createElement('div');
            $article.classList.add('is-article-title');
            $article.textContent = $row.title;
            $title.appendChild($article);
          }
          $movielink.appendChild($title);

          const $desc = document.createElement('div');
          $desc.classList.add('col-xs-12', 'mt10');
          {
            const $articledesc = document.createElement('div');
            $articledesc.classList.add('line-clamp', 'is-3');
            $articledesc.textContent = $row.description;
            $desc.appendChild($articledesc);
          }
          $movielink.appendChild($desc);
        }
        $div.appendChild($movielink);
      });
    }
    return $div;
  }
}

