import {htmlEscape} from "./createNewsRankingBlock";

export {createInActiveVotesBlock};

function createInActiveVotesBlock(data, options) {

  const $div = document.createElement('div');
  {
    if (data.questions.length > 0) {
      data.questions.forEach($row => {
        const $panel1div = document.createElement('div');
        $panel1div.classList.add('ballot-card', 'is-end');
        {
          const $info = document.createElement('div');
          $info.classList.add('ballot-card-info');
          {
            const $span = document.createElement('span');
            $span.classList.add('ballot-tag', 'is-end');
            $span.textContent = '受付終了';
            $info.appendChild($span);

            const $question = document.createElement('h3');
            $question.classList.add('ballot-title', 'is-after');
            let question = htmlEscape($row.question);
            question = question.replace(/\r\n/g, "<br />");
            question = question.replace(/(\n|\r)/g, "<br />");
            question = question.replace(/　/g, "<br />");
            $question.innerHTML = question;
            $info.appendChild($question);
          }
          $panel1div.appendChild($info);

          const $detail = document.createElement('div');
          $detail.classList.add('ballot-card-detail');
          {
            {
              if ($row.my_vote_answer != null) {
                const $p = document.createElement('p');
                $p.classList.add('ballot-notes');
                $p.textContent = 'あなたの回答：' + $row.my_vote_answer;
                $detail.appendChild($p);
              }

              $row.answers.forEach($each_answer => {
                const $graph = document.createElement('div');
                $graph.classList.add('ballot-graph');
                {
                  const $ans_span = document.createElement('span');
                  $ans_span.classList.add('ballot-graph-text');
                  $ans_span.textContent = $each_answer.answer;
                  $graph.appendChild($ans_span);

                  const $percent_span = document.createElement('span');
                  $percent_span.classList.add('ballot-graph-number');
                  $percent_span.setAttribute('data-percentage', $each_answer.vote_percent + '%');
                  $graph.appendChild($percent_span);
                }
                $detail.appendChild($graph);
              });
              const $notes = document.createElement('p');
              $notes.classList.add('ballot-notes', 'is-right');
              $notes.textContent = $row.total_vote_count + '票';
              $detail.appendChild($notes);
            }
          }
          $panel1div.appendChild($detail);
        }
        $div.appendChild($panel1div);
      });
    }
  }
  return $div;

}

